<template>
    <v-container -max-width-limited>
        <v-skeleton-loader
                v-if="!keycloakUsers"
                type="image"
        ></v-skeleton-loader>
        <v-alert
                text
                :type="messageType"
                v-if="message"
        >
            {{ message }}
        </v-alert>
        <v-card :color="'#eee'" class="mt-5 mb-5" v-for="keycloakUser in keycloakUsers" :key="keycloakUser.id">
            <v-container>
                <div class="col-md-12">
                    <h2 v-if="keycloakUser.realm === 'isc'">{{ $t('MyIsc Customer Informationen') }}</h2>
                    <h2 v-else>{{ $t('MyEinhell Customer Informationen') }}</h2>
                    <v-row>
                        <v-col>
                            <table>
                                <caption></caption>
                                <thead>
                                <tr>
                                    <th id="default"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <label>{{ $t('Username') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ keycloakUser.username }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>{{ $t('E-Mail') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ keycloakUser.email }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>{{ $t('Name') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ keycloakUser.firstName }} {{ keycloakUser.lastName }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>{{ $t('Created on') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ convertTimestamp(keycloakUser.createdTimestamp) }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>{{ $t('Realm') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ keycloakUser.realm }}</strong>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </v-col>
                        <v-col>
                            <table>
                                <caption></caption>
                                <thead>
                                <tr>
                                    <th id="default"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <label>{{ $t('Active') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ $t(boolValue(keycloakUser.enabled)) }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>{{ $t('E-mail verified') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ $t(boolValue(keycloakUser.emailVerified)) }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>{{ $t('Multiple authentication') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong>{{ $t(boolValue(keycloakUser.totp)) }}</strong>
                                    </td>
                                </tr>
                                <tr v-if="keycloakUser.attributes">
                                    <td>
                                        <label>{{ $t('Language') }}: </label>
                                    </td>
                                    <td class="value">
                                        <strong v-for="locale in keycloakUser.attributes.locale"
                                                :key="locale">{{ $t('main.languages.' + locale) }}</strong>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </v-col>
                    </v-row>
                    <v-btn
                            @click="showDetails(keycloakUser.id, keycloakUser.realm)"
                    >
                        {{ $t('Show Details') }}
                    </v-btn>
                </div>
            </v-container>
        </v-card>
        <v-btn
                @click="$router.back()"
                class="back-btn"
        >
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t('Back') }}
        </v-btn>
    </v-container>
</template>

<script>
import {convertTimestamp} from "@/services/globalFunctions";
import SearchService from "@/services/SearchService";

export default {
    name: "KeycloakUserInformation",
    data: () => ({
        keycloakUsers: null,
        message: '',
        messageType: ''
    }),
    mounted() {
        const email = this.$route.params.email
        SearchService.getMyEinhellUsers(email)
            .then(value => {
                this.keycloakUsers = value
            })
            .catch(reason => {
                if (reason['errors'] !== undefined) {
                    this.$router.push({
                        name: 'search',
                        query: {message: reason.errors}
                    })
                } else {
                    console.log(reason)
                }
            });
    },
    methods: {
        convertTimestamp(timestamp) {
            return convertTimestamp(timestamp);
        },
        boolValue(value) {
            if (value) {
                return "Yes"
            }
            return "No"
        },
        showDetails(id, realm) {
            this.$router.push({
                name: 'user_detail',
                params: {
                    id: id,
                    realm: realm
                }
            })
        },
        errorResponse(errorMessage) {
            this.message = this.$i18n.t(errorMessage);
            this.messageType = 'error';
            this.$vuetify.goTo(0)
        },
        successResponse(errorMessage) {
            this.message = this.$i18n.t(errorMessage);
            this.messageType = 'success';
            this.$vuetify.goTo(0)
        }
    }
}
</script>

<style scoped>

</style>
